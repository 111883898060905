/* eslint-disable @typescript-eslint/no-loop-func */
import type { Collection } from '@inwink/inwinkdb';
import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import { replaceAll } from '@inwink/utils/methods/replaceall';
import { logging } from '@inwink/logging';
import type { ILoggerBase } from '@inwink/logging/logging/basetypes';
import type { IRequestManager, IEventRequests } from '../services/apiaccessprovider.definition';
import type { States } from '../services/services';
import { metadataSyncActions } from '../services/appmetadataactions/sync';
import type { IFrontDatasourceV3 } from '../api/front/datasource';
import type { IInwinkEntityV3QueryOptions } from '../api/front';

const datalogger = logging.getLogger("Data");

export declare type IProgressCallback = (arg?: { collectionname: string, message?: string, progressPercent: number }) => void;
export declare type IItemCallback<T> = (item: T) => any;
export declare type IEntitySyncCallback = (
    requestMgr: IRequestManager,
    logger: ILoggerBase,
    eventconfig: Entities.IEventDetailConfiguration,
    eventData: States.IDataStore,
    force: boolean, trackProgress?: IProgressCallback
) => Promise<any>;
export interface IUrlRewrite {
    source: string;
    target: string;
}

export interface IEventEntitiesSyncBaseArgs {
    requestMgr: IRequestManager;
    logger: ILoggerBase;
    force: boolean;
    dataStore: States.IDataStore;
    trackProgress?: IProgressCallback;
    rewriteUrls?: IUrlRewrite[];
}

export interface IEventSingleEntitySyncArgs extends IEventEntitiesSyncBaseArgs {
    dispatch;
    getState: () => States.IAppState;
    entitysyncfunction: IEntitySyncCallback;
}

export interface IEventEntitiesCollectionNameSyncArgs<T> extends IEventEntitiesSyncBaseArgs {
    apiCallProvider: (lastsync?: Date) => Promise<any>;
    collectionname: string;
    itemkey: (refitem:T, item: T) => boolean;
    itemcallback: (item: T) => void;
}

export interface IEventEntitySync {
    eventDetail: Entities.IEventDetail;
    eventRequests: IEventRequests;
    configuration: Entities.IEventDetailConfiguration;
    logger: ILoggerBase;
    force: boolean;
    noCache?: boolean
    dispatch;
    getState: () => States.IAppState;
    eventData: States.IEventDataStore;
    trackProgress: IProgressCallback;
    rewriteUrls?: IUrlRewrite[];
    updatedEntityTemplates?: Entities.IFieldTemplate[];
}

export interface IEventUserEntitySync {
    eventRequests: IEventRequests;
    logger: ILoggerBase;
    configuration: Entities.IEventDetailConfiguration;
    userData: States.IPersonDataStore;
    force: boolean;
    trackProgress?: IProgressCallback;
    rewriteUrls?: IUrlRewrite[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IEventEntitiesCollectionSyncArgs<T extends object> extends IEventEntitiesCollectionNameSyncArgs<T> {
    collection: Collection<T>;
}

export function syncSingleEntity(args: IEventSingleEntitySyncArgs): Promise<any> {
    metadataSyncActions.isSyncingEventData(true)(args.dispatch);
    return new Promise((resolve, reject) => {
        const state = args.getState();
        let logger: ILoggerBase = datalogger;
        if (state.appMetaData.logcontext) {
            logger = datalogger.context(state.appMetaData.logcontext);
        }

        if (state.event.data.syncInProgess) {
            logger.debug("DATA sync is already in progress");
            resolve(false);
            return;
        }

        if (state.event.data && state.event.data.eventDetail && state.event.data.eventDetail.data.length) {
            const eventConfig = state.event.data.eventDetail.data[0].configuration;
            state.event.data.syncInProgess = true;
            return args.entitysyncfunction(
                args.requestMgr,
                args.logger,
                eventConfig,
                state.event.data,
                args.force,
                args.trackProgress
            ).then((haschanges) => {
                if (haschanges === true) {
                    return state.event.data.save().then(() => {
                        if (args.trackProgress) args.trackProgress();
                        return haschanges;
                    });
                }
            }).then(() => {
                state.event.data.syncInProgess = false;
                metadataSyncActions.isSyncingEventData(false)(args.dispatch);
                resolve();
            }, (err) => {
                state.event.data.syncInProgess = false;
                metadataSyncActions.isSyncingEventData(false)(args.dispatch);
                reject(err);
            });
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
            message: "event detail not found"
        });
    });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function syncEntity<T extends object>(args: IEventEntitiesCollectionNameSyncArgs<T>) {
    const collection = args.dataStore[args.collectionname];

    return syncEntityCollection({ ...args, collection });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function syncEntityCollection<T extends object>(
    args: IEventEntitiesCollectionSyncArgs<T>
    // logger:ILoggerBase,
    // eventData: States.IDataStore,
    // force: boolean,
    // collectionname: string,
    // collection: any,
    // apiCallProvider: (lastsync?: Date) => Promise<any>,
    // itemkey: (item: T) => any,
    // itemcallback: (item: T) => void,
    // progresscallback: IProgressCallback
) {
    let lastSync = args.dataStore.lastsyncs.data.find((s) => s.key === args.collectionname);
    let lastSyncDate;
    let insertLastSync = false;

    if (lastSync) {
        lastSyncDate = new Date(lastSync.date as string);
        lastSync = Object.assign({}, lastSync) as any;

        const itemSync = collectionLastSync(args.collection);
        if (itemSync) {
            lastSyncDate = moment(itemSync);
            lastSync.date = lastSyncDate.toDate().toISOString();
        }
    } else {
        insertLastSync = true;
        lastSync = {
            key: args.collectionname,            
            date: new Date().toISOString()
        } as any;
    }

    lastSync.collection = args.collection.name;

    if (args.force) {
        lastSyncDate = null;
    }

    const apiCall = args.apiCallProvider(lastSyncDate);
    return apiCall.then((entities: T[]) => {
        if (entities) {
            args.logger.verbose("DATA received " + entities.length + " " + args.collectionname + " (from " + lastSyncDate + ")");
            if (args.force) {
                args.collection.removeWhere(() => true);
            }

            if (entities.length) {
                entities.forEach((entity: T) => {
                    syncCollectionEntity(args, lastSync, args.collection, entity);                    
                });

                const existing = args.dataStore.lastsyncs.data.find((s) => s.key === args.collectionname);
                checkCollectionLastSync(insertLastSync && !existing, args.dataStore, lastSync, args.collection);
                
            }
        }
    }).then(() => {
        if (args.trackProgress) args.trackProgress({ collectionname: args.collectionname, progressPercent: 100 });
    }, (err) => {
        args.logger.error("error syncing data for " + args.collectionname, err);
        if (args.trackProgress) args.trackProgress({ collectionname: args.collectionname, progressPercent: 100 });

        return Promise.reject(err);
    });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IRecursiveSync<T extends object> {
    datasource: Promise<IFrontDatasourceV3<T>>;
    logger:ILoggerBase;
    data: States.IDataStore;
    force: boolean;
    noCache?: boolean;
    getBaseOptions: (lastSync?: Date) => IInwinkEntityV3QueryOptions;
    countCallProvider?: (lastSync?: Date) => Promise<any>;
    apiCallProvider?: (index: number, pageSize: number, lastsync?: Date) => Promise<any>;
    removedProvider?: (lastSync?: Date) => Promise<{id:string}[]>;
    collection?: Collection<T>;
    collectionname: string;
    itemkey?: (refitem: T, item: T) => boolean;
    itemcallback: (item: T) => void;
    progressMessage?: string;
    progressCallback: IProgressCallback;
    itemsPerRequest: number;
    maxRecursion?: number;
    rewriteUrls?: IUrlRewrite[];
}


// eslint-disable-next-line @typescript-eslint/ban-types
function getArgs<T extends object>(args: IRecursiveSync<T>) : IRecursiveSync<T> {
    return Object.assign({}, {
        itemsPerRequest: args.itemsPerRequest || 200,
        itemkey: (sref: any, s) => {
            return sref.id === s.id;
        },

        countCallProvider: (lastSync) => {
            const initialOption: IInwinkEntityV3QueryOptions = args.getBaseOptions(lastSync);
            if (initialOption.selects) {
                delete initialOption.selects;
                delete initialOption.page;
            }

            return args.datasource.then((ds) => ds.count(initialOption)).then((res) => {
                return res;
            });
        },

        apiCallProvider: (idx: number, pageSize: number, lastSync) => {
            const initialOption: IInwinkEntityV3QueryOptions = args.getBaseOptions(lastSync);

            const option = Object.assign({}, initialOption, {
                page: Object.assign({ size: pageSize }, initialOption.page)
            });

            option.page.index = idx;

            return args.datasource.then((ds) => ds.query(option)).then((res) => res.data);
        },

        removedProvider: (lastSync) => {
            return args.datasource.then((ds) => ds.removed(moment(lastSync).utc().toISOString()));
        }
    }, args);
}

function checkCollectionLastSync<T extends object>(
    insertLastSync: boolean, 
    data: States.IDataStore,
    lastSync: Entities.ILastSync, collection : Collection<T>
) {
    if (insertLastSync && collection.data.length) {
        let newlastsync = null;
        collection.data.forEach((entity) => {
            const updated = (entity as any).validFrom
                || (entity as any).lastUpdate
                || (entity as any).lastUpdateDate;
            if (updated && (!newlastsync || updated > newlastsync)) {
                newlastsync = updated;
            }
        });
        if (newlastsync) {
            lastSync.date = newlastsync;
        }
    }

    if (insertLastSync) {
        data.lastsyncs.insert(lastSync);
    } else {
        data.lastsyncs.update(lastSync);
    }
}
// eslint-disable-next-line @typescript-eslint/ban-types
export function syncRecursiveEntity<T extends object>(
    baseargs: IRecursiveSync<T>
) {
    const args = getArgs(baseargs);
    
    let lastSync = args.data.lastsyncs.data.find((s) => s.key === args.collectionname);
    let lastSyncDate;
    let insertLastSync = false;
    const collection : Collection<T> = args.collection || args.data[args.collectionname];

    if (lastSync) {
        lastSyncDate = new Date(lastSync.date as string);
        lastSync = Object.assign({}, lastSync) as any;
        const itemSync = collectionLastSync(collection);
        if (itemSync) {
            lastSyncDate = moment(itemSync);
            lastSync.date = lastSyncDate.toDate().toISOString();
        }
    } else {
        insertLastSync = true;
        lastSync = {
            key: args.collectionname,
            date: new Date().toISOString()
        } as any;
    }
    lastSync.collection = collection.name;

    if (args.force) {
        lastSyncDate = null;
    }

    
    const progress = {
        collectionname: args.collectionname,
        message: args.progressMessage,
        progressPercent: 0
    };

    function checkRemoved() {
        if (lastSyncDate && args.removedProvider) {
            return args.removedProvider(lastSyncDate).then((removed) => {
                if (removed && removed.length) {
                    removed.forEach((r) => {
                        const existing = collection.data.find((e) => (e as any).id === r.id);
                        if (existing) {
                            collection.remove(existing);
                        }
                    });
                }
            }).then(null, (err) => {
                args.logger.error("error syncing removed items for " + args.collectionname, err);
            });
        }

        return Promise.resolve();
    }

    if (args.progressCallback) {
        args.progressCallback(progress);
    }

    return checkRemoved().then(() => args.countCallProvider(lastSyncDate)).then((totalNbItems) => {
        if (__SERVERSIDE__) {
            return syncWithStream(args, totalNbItems, collection, insertLastSync, lastSync, lastSyncDate, progress);
        }
        return syncWithPageBatches(args, totalNbItems, collection, insertLastSync, lastSync, lastSyncDate, progress);
    }).then(() => {
        checkCollectionLastSync(insertLastSync, args.data, lastSync, collection);
    }).then(() => {
        progress.progressPercent = 100;
        if (args.progressCallback) {
            args.progressCallback(progress);
        }
    }, (err) => {
        baseargs.logger.error("sync error", err);
        progress.progressPercent = 100;
        if (args.progressCallback) {
            args.progressCallback(progress);
        }

        return Promise.reject(err);
    });
}

function syncWithStream<T extends object>(
    args: IRecursiveSync<T>,
    totalNbItems: number,
    collection : Collection<T>,
    insertLastSync: boolean,
    lastSync: Entities.ILastSync,
    lastSyncDate: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    progress: any
) : Promise<boolean> {
    const initialOption: IInwinkEntityV3QueryOptions = args.getBaseOptions(lastSyncDate);
    const option = Object.assign({}, initialOption, {
        page: null
    });

    let hasData = false;

    return args.datasource.then((ds) => ds.stream(option)).then(async (res) => {
        if (res.status == 200) {
            
            for await (const entity of parseJsonStream(res.body)) {
                if (entity) {
                    hasData = true;
                    syncCollectionEntity(args, lastSync, collection, entity);
                }
            }
        }        
    }).then(() => {
        return hasData;
    });
}

async function *parseJsonStream(readableStream: ReadableStream<Uint8Array>) {
    for await (const line of readLines(readableStream.getReader())) {
        const trimmedLine = line.trim().replace(/,$/, '');

        if (trimmedLine !== '[' && trimmedLine !== ']') {
            yield JSON.parse(trimmedLine);
        }
    }
}

async function *readLines(reader: ReadableStreamDefaultReader<Uint8Array>) {
    const textDecoder = new TextDecoder();
    let partOfLine = '';
    for await (const chunk of readChunks(reader)) {
        const chunkText = textDecoder.decode(chunk);
        const chunkLines = chunkText.split('\n');
        if (chunkLines.length === 1) {
            partOfLine += chunkLines[0];
        } else if (chunkLines.length > 1) {
            yield partOfLine + chunkLines[0];
            for (let i = 1; i < chunkLines.length - 1; i++) {
                yield chunkLines[i];
            }
            partOfLine = chunkLines[chunkLines.length - 1];
        }
    }
}

function readChunks(reader: ReadableStreamDefaultReader<Uint8Array>) {
    return {
        async* [Symbol.asyncIterator]() {
            let readResult = await reader.read();
            while (!readResult.done) {
                yield readResult.value;
                readResult = await reader.read();
            }
        },
    };
}

function syncCollectionEntity<T extends object>(
    args: IRecursiveSync<T> | IEventEntitiesCollectionSyncArgs<T>,
    lastSync: Entities.ILastSync, collection : Collection<T>, entity: T
) {
    if (args.rewriteUrls) {
        entityUrlRewrite(entity, args.rewriteUrls);
    }

    if ((entity as any).validFrom || (entity as any).lastUpdate || (entity as any).lastUpdateDate) {
        const updated = (entity as any).validFrom
            || (entity as any).lastUpdate
            || (entity as any).lastUpdateDate;
        if (updated && (!lastSync.date || updated > lastSync.date)) {
            lastSync.date = updated;
        }
    }

    if (args.itemcallback) args.itemcallback(entity);

    const existing = collection.data.find((e) => args.itemkey(entity, e));
    if (existing) {
        Object.assign(existing, entity);
        collection.update(existing);
    } else {
        collection.insert(entity);
    }

    return true;
}

function syncWithPageBatches<T extends object>(
    args: IRecursiveSync<T>,
    totalNbItems: number,
    collection : Collection<T>,
    insertLastSync: boolean,
    lastSync: Entities.ILastSync,
    lastSyncDate: any,
    progress: any
) : Promise<boolean> {
    const syncQueue: Array<() => void> = [];
    const nbBatches = Math.ceil(totalNbItems / args.itemsPerRequest);
    let currentTriggeredBatch = 0;
    if (nbBatches === 0) {
        return Promise.resolve(false);
    }

    function enqueueRecursiveEntity(_idx, _entities: T[]) {
        datalogger.verbose("enqueuing chunk " + _idx + "/" + nbBatches);

        syncQueue.unshift(() => {
            try {
                if (args.force && _idx === 0) {
                    collection.removeDataOnly();
                }

                _entities.forEach((entity: T) => {
                    syncCollectionEntity(args, lastSync, collection, entity);
                });
            } catch (ex) {
                datalogger.error("unexpected sync error", ex);
            }
        });

        const next = () => {
            const length = syncQueue.length;
            const pending = syncQueue.pop();
            if (pending) {
                datalogger.verbose("dequeuing chunk " + length + "(remains " + syncQueue.length + ")");
                pending();
                next();
            }
        };
        setTimeout(() => {
            next();
        }, 0);
    }

    let hasData = false;
    const apiCall = () => {
        const idx = currentTriggeredBatch;
        currentTriggeredBatch++;

        args.logger.verbose("batch api call " + args.collectionname + " " + (idx + 1) + "/" + nbBatches);
        return args.apiCallProvider(idx, args.itemsPerRequest, lastSyncDate).then((entities: T[]) => {
            progress.progressPercent = 100 * (idx / nbBatches);
            if (args.progressCallback) {
                args.progressCallback(progress);
            }

            if (entities) {
                hasData = true;
                args.logger.verbose("received " + entities.length
                    + " " + args.collectionname
                    + " (from " + lastSyncDate + ")");

                if (entities.length) {
                    enqueueRecursiveEntity(idx, entities);

                    if (entities.length === args.itemsPerRequest && (args.maxRecursion ? (args.maxRecursion < idx) : true)) {
                        return apiCall();
                    }

                    return Promise.resolve();
                }
                return Promise.resolve();
            }
            return Promise.resolve();
        }, (err) => {
            args.logger.error("error getting batch " + args.collectionname + " " + idx + "/" + nbBatches, err);
            return Promise.reject(err);
        });
    };

    const start : Promise<any> = apiCall();

    return start.then(() => {
        const check = () => {
            if (syncQueue.length > 0) {
                return new Promise((resolve) => setTimeout(() => check().then(resolve), 20));
            }

            return Promise.resolve();
        };

        return check();
    }).then(() => {
        return hasData;
    });
}

export function collectionLastSync(collection) {
    if (collection && collection.data && collection.data.length) {
        const firstItem = collection.data[0];
        let itemSync = firstItem.validFrom || firstItem.lastUpdate || firstItem.lastUpdateDate || firstItem.statusDate;
        collection.data.forEach((item) => {
            const itemDate = item.validFrom || item.lastUpdate || item.lastUpdateDate || item.statusDate;
            if (itemDate > itemSync) {
                itemSync = itemDate;
            }
        });

        if (itemSync) {
            return itemSync;
        }
    }
}

export function entityUrlRewrite(_entity: any, rewrites: IUrlRewrite[]) {
    if (_entity) {
        const entity = _entity;
        Object.keys(entity).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(entity, key)) {
                let val = entity[key];
                const valType = typeof val;
                if (valType === "string") {
                    rewrites.forEach((v) => {
                        if (v && v.source && v.target && ((val as string).indexOf(v.source) >= 0)) {
                            val = replaceAll(val, v.source, v.target);
                        }
                    });
                    entity[key] = val;
                } else if (Array.isArray(val)) {
                    entity[key] = val.map((it) => {
                        let item = it;
                        if (typeof item === "string") {
                            rewrites.forEach((v) => {
                                if (v && v.source && v.target && ((item as string).indexOf(v.source) >= 0)) {
                                    item = replaceAll(item, v.source, v.target);
                                }
                            });
                            return item;
                        }
                        if (valType === "object") {
                            entityUrlRewrite(item, rewrites);
                            return item;
                        }
                        return null;
                    });
                } else if (valType === "object") {
                    entityUrlRewrite(val, rewrites);
                }
            }
        });
    }
}

export function getDate(m: moment.Moment, removeOffset: boolean) {
    return removeOffset ? m.toISOString(true).split('.')[0] : m.toISOString();
}
