export const metadataSyncActions = {
    isSyncingUserData(syncing: boolean) {
        return (dispatch) => {
            dispatch({ type: "METADATA_USERSYNC", payload: syncing });
        };
    },

    isSyncingEventData(syncing: boolean) {
        return (dispatch) => {
            dispatch({ type: "METADATA_EVENTSYNC", payload: syncing });
        };
    },

    isSyncingCommunityData(syncing: boolean) {
        return (dispatch) => {
            dispatch({ type: "METADATA_COMMUNITYSYNC", payload: syncing });
        };
    },

    isSyncingWebsiteData(syncing: boolean) {
        return (dispatch) => {
            dispatch({ type: "METADATA_ROOTWEBSITESYNC", payload: syncing });
        };
    },
};
